// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

// mobil - normal
h1 { font-size: 4.2rem; line-height: 1.2;  letter-spacing: .1rem;  }
h2 { font-size: 3.4rem; line-height: 1.25; letter-spacing: .1rem;  }
h3 { font-size: 2.8rem; line-height: 1.3;  letter-spacing: -.1rem;  }
h4 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
h5 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0;       }

// Larger than phablet
@media (#{$bp-larger-than-phablet}) {
  h1 { font-size: 5.2rem; letter-spacing: 0.2rem; }
  h2 { font-size: 4.2rem; letter-spacing: 0.1rem; }
  h3 { font-size: 3.6rem; letter-spacing: -0.1rem; }
  h4 { font-size: 3.0rem; letter-spacing: -0.1rem; }
  h5 { font-size: 2.4rem; letter-spacing: -0.1rem; }
  h6 { font-size: 1.5rem; letter-spacing: -0.1rem; }
}

/*__________font-basis-styles__________*/

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: left;
  font-style: normal;
  // font-weight: normal;
}

h1{
  font-weight:700;
}

li{
  list-style: none;
}

ul{
  padding-left:-40px;
}

p {
  margin-top: 0;
}


/*__________font-extra-styles__________*/

.jp-font-400{
  font-family: 'Sawarabi Mincho', Arial, Helvetica !important;
  text-align: left;
  font-weight: 400;
  //color:blue;
}

.jp-font-700{
  font-family: 'Sawarabi Gothic', Arial, Helvetica !important;
  text-align: left;
  font-weight: 700;
  //color:blue;
}

.jp-font{
  font-family: "Noto Serif CJK JP", Arial, Helvetica !important;
  text-align: left;
  font-weight: 400;
  //color:blue;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.text-caps{
  text-transform: uppercase;
}

.text-center{
  text-align:center;
}

.text-right{
  text-align:right;
}

.high-light{
  color: $color-red;
}
