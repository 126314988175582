// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Typography
$font-family: 'Noto Serif', Arial, sans-serif; //'Minion Pro' alternative font
//$fa-font-path: "../fontawesome-webfonts";
$line-height: 1.735;
$font-size:15px;

// Breakpoints
$bp-larger-than-mobile    : "min-width: 400px" !default;
$bp-larger-than-phablet   : "min-width: 600px" !default;
$bp-larger-than-tablet    : "min-width: 780px" !default;
$bp-larger-than-desktop   : "min-width: 1000px" !default;
$bp-larger-than-desktophd : "min-width: 1200px" !default;

// Colors old
$light-grey: #e1e1e1 !default;
$dark-grey: #333 !default;
$primary-color: #33c3f0 !default;
$secondary-color: lighten($dark-grey, 13.5%) !default;
$border-color: #bbb !default;
$link-color: #DC5940 /*!default*/;
$font-color: #222 !default;


/*__________basis-colors__________*/
/* Styles from Figma*/
$color-primary:#DC5940;

$color-fontDark:#333333;
$color-fontLight:#F2F2F2;
$color-bg:#F2F2F2;
$color-red:#DC5940;
$color-blue:#657ED3;
$color-yellow:#FED766;
$color-orange:#dc5940; /*color befor, #f2994a*/
$color-lemongreen:#31ed82;
$color-text-shadow:#111;

$color-secondary: lighten($dark-grey, 25%) !default;


//Grid Variables
$container-width: 1136px !default;
$container-width-larger-than-mobile: 85% !default;
$container-width-larger-than-phablet: 80% !default;
$total-columns: 12 !default;
$column-width: 100 / $total-columns !default; // calculates individual column width based off of # of columns
$column-margin: 2%; //4% !default; // space between columns

// Misc
$global-radius: 4px !default;

// Card Headline Underline
$underlineColor: $color-primary;
$underlineHeight: 0%;
